import { Config } from '@/config';
import apiService from "@/services/api.service";
import axios, { CancelTokenSource } from 'axios';

const CancelToken = axios.CancelToken;
let cancelTokenPlatforms: CancelTokenSource | null = null;

class MailingListService {
  async createVerificationCode(email: string, locale: string, parent: number, parent_id: number) {
    if (cancelTokenPlatforms) {
      cancelTokenPlatforms.cancel('Multiple requests.');
    }
    cancelTokenPlatforms = CancelToken.source();

    try {
      const requestData = { email, locale, parent, parent_id };
      const response = await apiService.post(`${Config.api.backend.urlV2}/mailingLists/create-verification-code`, requestData);
      return response.data;
    } catch (error) {
      console.error(error)
    }

  }


  async checkVerificationCode(email: string, code: string): Promise<boolean> {
if (cancelTokenPlatforms) {
      cancelTokenPlatforms.cancel('Multiple requests.');
    }
    cancelTokenPlatforms = CancelToken.source();

    try {
      const requestData = {
            email, code
        };
      const response = await apiService.post(`${Config.api.backend.urlV2}/mailingLists/check-verification-code`, requestData);
      return response;
    } catch (error) {
      console.error(error)
    }
  }

  async subscribe(parent: string, parent_id: number, user_id: number): Promise<boolean> {

    if (cancelTokenPlatforms) {
      cancelTokenPlatforms.cancel('Multiple requests.');
    }
    cancelTokenPlatforms = CancelToken.source();

    try {
      const requestData = {
            userId: user_id
        };
      const response = await apiService.post(`${Config.api.backend.urlV2}/mailingLists/${parent}/${parent_id}/subscribe`, requestData);
      return response.data;
    } catch (error) {
      console.error(error)
    }
  }
}

export const mailingListService = new MailingListService();
