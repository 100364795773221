<script setup lang="ts">
import { computed } from "vue";
import { getTranslation } from "@/utils/data-display-utils";
import { Module, ProjectModuleImageTextConfigDto } from '@/store/module.interface';

const props = defineProps<{
    module: Module;
}>();

const moduleConfig = props.module.config as ProjectModuleImageTextConfigDto;
const hasImage = computed(() => moduleConfig.image && moduleConfig.image.url !== '');
</script>

<template>
    <div class="text-module-wrapper">
        <div :class="{ 'highlight': moduleConfig.highlight }">
            <div class="text-module">
                <div class="text-module__content">
                    <div class="text-module__content-image col"
                         v-if="hasImage && moduleConfig.imageAlignment !== 2">
                        <img :src="moduleConfig.image.url" />
                    </div>
                    <div class="text-module__content-text col">
                        <h3>{{ getTranslation(props.module, 'title') }}</h3>
                        <div v-html="getTranslation(moduleConfig, 'content')" />
                    </div>
                    <div class="text-module__content-image col"
                         v-if="hasImage !== '' && moduleConfig.imageAlignment === 2">
                        <img :src="moduleConfig.image.url" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.text-module-wrapper {
    width: 100%;
    margin-bottom: $space-md;

    .highlight {
        background-color: #cedbe4;
        border-radius: 8px;
        padding: $space-lg;
    }

    .text-module {
        display: flex;
        flex-direction: column;
        gap: $space-md;

        h3 {
            margin-bottom: $space-sm !important;
        }

        &__content {
            display: flex;
            flex-direction: row;
            gap: $space-lg;

            &-text {
                font-size: $body-text;
            }

            &-image {
                img {
                    width: 100%;
                    max-width: 360;
                    border-radius: 8px;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .text-module-wrapper {
        .text-module {
            &__content {
                flex-direction: column;
            }
        }
    }
}
</style>