<script setup lang="ts">

import { LanguageTranslation } from '@/store/language.interface';
import { getTranslation } from '@/utils/data-display-utils';
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import { vueVimeoPlayer } from 'vue-vimeo-player';

// PROPS
const props = defineProps<{
    type: string
    title?: LanguageTranslation
    videoId: LanguageTranslation
}>();

</script>
<template>
    <div class="video-player">
        <div v-if="props.type === 'Youtube'">
            <LiteYouTubeEmbed
                    :id="getTranslation(props, 'videoId')"
                    :title="getTranslation(props, 'title')"
            />
        </div>
        <div v-else>
            <vue-vimeo-player ref="player" :video-id="getTranslation(props, 'videoId')"/>
        </div>
    </div>    
</template>
<style lang="scss">
.video-player {
    iframe {
        width: 100% !important;
    }
}
</style>