import { Config } from '@/config';
import apiService from "@/services/api.service";
import { useCommunityStore } from '@/store/communityStore';

class ModulesService {
    async getProjectModules(projectId: number) {
        try {
            const response = await apiService.get(
                `${Config.api.backend.urlV2}/projects/${projectId}/modules`,
            );

            const modulesData = response.data

            if(modulesData) {
                const communityStore = useCommunityStore();
                communityStore.setProjectModules(modulesData, projectId);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        }
        
    }
}

export const modulesService = new ModulesService();