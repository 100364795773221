<script setup lang="ts">

import { getTranslation } from "@/utils/data-display-utils";
import { Module, ProjectModuleVideoConfigDto } from '@/store/module.interface';
import VideoPlayer from "../VideoPlayer.vue";

const props = defineProps<{
    module: Module;
}>();

const moduleConfig = props.module.config as ProjectModuleVideoConfigDto;

</script>

<template>
    <div class="video-module-wrapper">
        <div :class="{ 'highlight': moduleConfig.highlight }">
            <div class="video-module">
                <div class="video-module__left-align" v-if="moduleConfig.videoAlignment === 1">
                    <div class="video-player col">
                        <VideoPlayer :type="moduleConfig.videoType" :video-id="moduleConfig.video" :title="moduleConfig.content"/>
                    </div>
                    <div class="caption col">
                        <h3>{{ getTranslation(props.module, 'title') }}</h3>
                        <div v-html="getTranslation(moduleConfig, 'content')" />
                    </div>
                </div>
                <div class="video-module__center-align" v-if="moduleConfig.videoAlignment === 3">
                    <div class="video-player">
                        <VideoPlayer :type="moduleConfig.videoType" :video-id="moduleConfig.video" :title="moduleConfig.content"/>
                    </div>
                    <div class="caption">
                        <h3>{{ getTranslation(props.module, 'title') }}</h3>
                        <div v-html="getTranslation(moduleConfig, 'content')" />
                    </div>
                </div>
                <div class="video-module__right-align" v-if="moduleConfig.videoAlignment === 2">
                    <div class="caption col">
                        <h3>{{ getTranslation(props.module, 'title') }}</h3>
                        <div v-html="getTranslation(moduleConfig, 'content')" />
                    </div>
                    <div class="video-player col">
                        <VideoPlayer :type="moduleConfig.videoType" :video-id="moduleConfig.video" :title="moduleConfig.content"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.video-module-wrapper {
    width: 100%;
    margin-bottom: $space-md;

    .highlight {
        background-color: #cedbe4;
        border-radius: 8px;
        padding: $space-lg;
    }

    .video-module {
        display: flex;
        flex-direction: column;
        gap: $space-md;

        h3 {
            margin-bottom: $space-sm !important;
        }

        &__left-align {
            display: flex;
            flex-direction: row;
            gap: $space-md;
        }

        &__right-align {
            display: flex;
            flex-direction: row;
            gap: $space-md;
        }

        &__center-align {
            display: flex;
            flex-direction: column;
            gap: $space-md;
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .video-module-wrapper {
        .video-module {
            &__right-align, &__left-align {
                flex-direction: column;
            }
        }
    }
}
</style>