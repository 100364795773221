<script setup lang="ts">
import { StoredFile } from '@/store/common.interface';


const props = defineProps<{
    banner?: StoredFile,
    text: string
}>();

</script>

<template>
    <div class="description">
        <div class="description__content">
            <div class="description__content-image">
                <img v-if="banner" :src="banner.url" />
            </div>
            <div class="description__content-text"
                 v-html="props.text" />
        </div>
    </div>
</template>

<style lang="scss">
.description {
    &__content {
        display: flex;
        flex-direction: column;
        gap: $space-lg;

        &-image>img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 8px;
        }

        &-text {
            font-size:  $body-text;
        }
    }
}
</style>