<script setup lang="ts">

import BaseButton from './base/BaseButton.vue';
import useResponsive from '@/utils/responsive-util';
import { paths } from "@/utils/paths";
import { useI18n } from "vue-i18n";


// CONST
const { isMobile } = useResponsive()
const { t } = useI18n();

// PROPS
const props = defineProps<{
    index: number,
    hasResponse?: boolean,
    isVoting: boolean,
    showSubmit: boolean,
    showResult: boolean,
    isFinalQuestion: boolean,
    consultationIsCompleted: boolean,
    selectedResponses: any,
    formType: string
}>();

// EMITS
const emit = defineEmits<{
    (e: "submit"): void;
    (e: "nextForm", index: number): void;
    (e: "resetResponse"): void;
    (e: "undoCancelVote"): void;

}>();

// FUNCTIONS
function submit() {
    emit('submit')
}

function resetResponse() {
    emit("resetResponse");
}

function scrollNextQuestion() {
    emit("nextForm", props.index);
}

function undoCancelVote() {
    emit("undoCancelVote");
}

</script>

<template>
    <div class="form-buttons-container" v-if="!consultationIsCompleted && (showSubmit || showResult)">
        <div v-if="isVoting && props.selectedResponses.length === 0 && !props.hasResponse && !showSubmit" class="form-buttons-container__undo-cancel">
            <BaseButton
                class="secondary"
                :text="t('buttons.cancel')"
                fullWidth
                @click="undoCancelVote"
            ></BaseButton>
        </div>
        <div v-if="showSubmit && isVoting" class="form-buttons-container__question">
            <BaseButton
                v-if="props.formType != 'open'"
                class="secondary"
                :text="t('buttons.cancel')"
                fullWidth
                @click="resetResponse"
            ></BaseButton>
            <BaseButton
                class="action"
                :text="t('buttons.submit')"
                fullWidth
                @click="submit"
            ></BaseButton>
        </div>
        <div v-if="showResult && !isVoting" class="form-buttons-container__poll">
            <div class="form-buttons-container__poll-stats">
                <BaseButton
                    v-if="!isMobile"
                    class="secondary"
                    left-icon="undo"
                    :text="t('buttons.change-vote')"
                    @click="resetResponse"
                ></BaseButton>

                <BaseButton
                    v-else
                    class="secondary"
                    left-icon="undo"
                    @click="resetResponse"
                ></BaseButton>
            </div>
            <div class="form-buttons-container__poll-next" v-if="!props.isFinalQuestion">
                <BaseButton
                    class="action"
                    :fullWidth="true"
                    icon="navigate_next"
                    :text="t('buttons.next')"
                    @click="scrollNextQuestion"
                ></BaseButton>
            </div>
            <div class="form-buttons-container__poll-thanks" v-else>
                <BaseButton
                    class="action"
                    :fullWidth="true"
                    :text="t('consultation.thank-you')"
                    :to="paths.root"
                ></BaseButton>
            </div>
        </div>
    </div>
    
    <div class="form-buttons-container" v-else-if="consultationIsCompleted">
        <div class="form-buttons-container__poll-next" v-if="!props.isFinalQuestion">
            <BaseButton
                class="action"
                icon="navigate_next"
                :text="t('buttons.next')"
                @click="scrollNextQuestion"
            ></BaseButton>
        </div>
        <div class="form-buttons-container__poll-thanks" v-else>
            <BaseButton
                class="action"
                :text="t('consultation.thank-you')"
                :to="paths.root"
            ></BaseButton>
        </div>
    </div>
</template>

<style lang="scss">
.form-buttons-container {
    display: flex;
    margin: $space-sm 0;
    gap: 5px;

    &__question {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: $space-sm;

        .q-btn {
            width: 100%;
        }
    }

    &__undo-cancel {
        width: 100%;

        .q-btn {
            width: 100%;
        }
    }

    &__results {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 5px;
    }

    &__poll {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 5px;

        &-stats, &-next, &-thanks {
            width: 100%;
            height: 100%;

            .q-btn {
                width: 100%;
                height: 100%;
            }
        }


    }

    &__cancel {
        display: flex;
        width: 100%;
    }
}
</style>