<script setup lang="ts">

import { provide, ref } from "vue";
import BaseValidationInput from "../base/BaseValidationInput.vue";
import BaseValidationTextArea from "../base/BaseValidationTextArea.vue";
import BaseButton from '@/components/base/BaseButton.vue';
import BaseImageUpload from "../base/BaseImageUpload.vue";

import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { formsService } from "@/services/forms.service";
import { currentLocale } from "@/plugins/i18n";

// PROPS
const props = defineProps<{
    sectionId: number;
}>();


const { handleSubmit, meta } = useForm({
    validationSchema: yup.object({
        title: yup.string().required(),
        content: yup.string().required(),
        image: yup.string().optional()

    }),
    initialValues: {
        title: '',
        content: '',
        image: ''
    }
});

// CONST
const activeLocale = ref<string>('fr');
const addImage = ref<boolean>(false);
const imageUrl = ref<string>(null);
const imageUploadRef = ref();


// EMITS
const emit = defineEmits<{
    (e: "cancel"): void;
    (e: "update", screen: string): void;
    (e: "closeDialog"): void;
    (e: "upload"): void; //needs image string eventually
}>();

provide('activeLocale', activeLocale);

// FUCNTIONS
function closeDialog() {
    emit('closeDialog');
}

function removeImage() {
    imageUrl.value = ''
}

function updateImage(image: string) {
    imageUrl.value = image
}

function clearImage() {
    imageUrl.value = ''
}

const submitProposition = handleSubmit(async (values, actions) => {
    let selectedLocale = currentLocale()
    console.debug('submitting', values);
    const question = {
        title: {
            [selectedLocale]: values.title
        },
        content: {
            [selectedLocale]: values.content
        },
        image: {}
    }
    if(addImage.value) {
        imageUrl.value = imageUploadRef.value.saveImage()
        await formsService.postUserForm(question, imageUrl.value, props.sectionId);
    } else {
        await formsService.postForm(question, props.sectionId);
    }
    actions.resetForm();
    emit('update', 'moderation');
});

</script>

<template>
    <div class="proposition-questions">
        <div class="proposition-questions__questions">
            <div class="proposition-questions__questions-title">
                <span> {{ $t('dialog.proposition.fields.title') }} </span>
                <base-validation-input name="title" label="title"/>
            </div>
            <div class="proposition-questions__questions-proposition">
                <span> {{ $t('dialog.proposition.fields.proposition') }} </span>
                <BaseValidationTextArea name="content" label="content"/>
            </div>
            <div class="proposition-questions__questions-image">
                <div class="checkbox">
                    <span>{{ $t('upload-image-modal.add-photo') }}</span>
                    <q-checkbox v-model="addImage"/>
                </div>
                <Transition name="proposition_image">
                    <div v-if="addImage" class="image-upload">
                        <BaseImageUpload
                            ref="imageUploadRef"
                            name="image"
                            :image="imageUrl"
                            type="card"
                            @remove="removeImage"
                            @update="updateImage"
                            @cancel="clearImage"
                            @save="updateImage"
                        />
                    </div>
                </Transition>
            </div>
        </div>
        <div class="proposition-questions__buttons">
            <BaseButton @click="closeDialog">{{ $t('buttons.cancel') }}</BaseButton>
            <BaseButton class="action" :disabled="!meta.valid" @click="submitProposition">
                {{ $t('buttons.submit') }}
            </BaseButton>
        </div>
    </div>
</template>

<style lang="scss">
.proposition-questions {
    display: flex;
    flex-direction: column;
    gap: $space-sm;

    &__buttons {
        display: flex;
        justify-content: end;
        gap: $space-md;
    }

    &__lang {
        display: flex;
        justify-content: end;
    }

    &__questions {
        display: flex;
        flex-direction: column;
        gap: $space-md;

        &-title {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: $space-sm;
        }

        &-proposition {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: $space-sm;

            textarea {
                border: none;
                min-height: 150px;
                border-radius: 4px;
                padding: $space-md;
                resize: vertical;
            }
        }

        &-image {
            display: flex;
            flex-direction: column;
            width: 100%;

            .image-upload {
                width: inherit;
            }

            .base-image-upload {
                width: inherit;
                height: 300px;
            }
        }
    }
}

.proposition_image-enter-active,
.proposition_image-leave-active {
    transition: opacity 0.5s eases;
}

.proposition_image-enter-from,
.proposition_image-leave-to {
    opacity: 0;
}
</style>