import axios, { AxiosRequestConfig } from "axios";
import { getHostname } from '@/utils/get-hostname';
import { authService } from '@/services/auth.service';


export interface ApiResponse<T> {
    success: boolean;
    data: T;
}

function getHeaders() {
    const headers: any = {
        "Content-Type": "application/json"
    };

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
    }
    headers['X-Community-Hostname'] = getHostname();

    return headers;
}

axios.interceptors.response.use(response => response, async (error) => {
  if (error.response.status === 401) {
    await authService.logoutUser();
    window.location.href = '/';
  } else if (error.response.status === 410) {
    location.reload();
  }
  return error;
});

export async function getRequest<TResponse = any>(url: string, body?: any, type: AxiosRequestConfig['responseType'] = 'json'): Promise<ApiResponse<TResponse>> {
    const config: AxiosRequestConfig = {
        method: 'get',
        url: url,
        headers: getHeaders(),
        params: body,
        responseType: type,
    };

    return (await request(config)).data as ApiResponse<TResponse>;
}

export async function postRequest<TBody = any, TResponse = any>(url: string, body?: TBody, type: AxiosRequestConfig['responseType'] = 'json'): Promise<ApiResponse<TResponse>> {

    const config: AxiosRequestConfig = {
        method: 'post',
        url: url,
        headers: getHeaders(),
        data: body,
        responseType: type
    };

    if (body instanceof FormData && config.headers) {
        delete config.headers['Content-Type'];
    }

    return (await request(config)).data as ApiResponse<TResponse>;
}

export async function putRequest<TBody = any, TResponse = any>(url: string, body?: TBody, type: AxiosRequestConfig['responseType'] = 'json'): Promise<ApiResponse<TResponse>> {
    const config: AxiosRequestConfig = {
        method: 'put',
        url: url,
        headers: getHeaders(),
        data: body,
        responseType: type
    };

    return (await request(config)).data as ApiResponse<TResponse>;
}

export async function patchRequest<TBody = any, TResponse = any>(url: string, body?: TBody, type: AxiosRequestConfig['responseType'] = 'json'): Promise<ApiResponse<TResponse>> {
    const config: AxiosRequestConfig = {
        method: 'patch',
        url: url,
        headers: getHeaders(),
        data: body,
        responseType: type
    };

    return (await request(config)).data as ApiResponse<TResponse>;
}

export async function deleteRequest<TBody = any, TResponse = any>(url: string, body?: TBody, type: AxiosRequestConfig['responseType'] = 'json'): Promise<ApiResponse<TResponse>> {
    const config: AxiosRequestConfig = {
        method: 'delete',
        url: url,
        headers: getHeaders(),
        data: body,
        responseType: type
    };

    return (await request(config)).data as ApiResponse<TResponse>;
}

export async function request(config: AxiosRequestConfig): Promise<any> {
    try {
        return await axios(config);
    } catch (err: any) {
        console.error(err);
        throw err;
    }
}

export default {
    get: getRequest,
    post: postRequest,
    put: putRequest,
    patch: patchRequest,
    delete: deleteRequest
};
