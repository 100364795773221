<script setup lang="ts">

import { ref, watch, computed } from "vue";
import supportingInfoContainer from "./supportingInfoContainer.vue";
import { getTranslation } from "@/utils/data-display-utils";
import { Section } from "@/store/section.interface";
import { useCommunityStore } from '@/store/communityStore';
import { QExpansionItem } from 'quasar';
import { bool } from "yup";

// STORE
const communityStore = useCommunityStore();

// PROPS
const props = defineProps<{
  sections: Array<Section>;
  activeSection: Section;
  consultationBanner?: string;
  context?: string;
  contextAttachment: Array<any>;
  contextOpened: number;
  attachmentsOpened: boolean;
}>();

// REFS
const theme = ref<number>(props.activeSection.orderNum);
const contextOpenedRef = ref<boolean>(props.activeSection.contextOpened === 1);

// EMITS
const emit = defineEmits<{
  (e: "changeSection", newValue: number): void;
}>();


// FUNCTIONS
function changeSection (id: number) {
  emit("changeSection", id);
}


// Watch for changes in the theme ref
watch( theme, (newValue) => {
    changeSection(newValue);
  }
);

watch(props, (newValue)=>{
  theme.value = props.sections.find(s => s.orderNum === newValue.activeSection.orderNum)?.orderNum;
  contextOpenedRef.value = newValue.activeSection.contextOpened;
})

// COMPUTED
const themeId = computed(() => {
  theme.value = props.activeSection.orderNum;
  return theme;
})

const themeCount = computed(() => {
  return props.sections.length;
})


</script>

<template>
  <div class="section-card" id="sectionContainer">
    <div class="section-card__banner">
      <div v-if="activeSection.bannerFile">
        <img :src="activeSection.bannerFile.url" alt="theme-banner">
      </div>
      <div v-else-if="consultationBanner">
        <img :src="consultationBanner" alt="theme-banner">
      </div>
      <div v-else>
        <img :src="communityStore.community.bannerFile.url" alt="theme-banner">
      </div>
    </div>
    <q-carousel
      v-model="theme"
      swipeable
      animated
      control-color="primary"
      navigation
      padding
      arrows
      class="rounded-borders"
    >
      <template v-slot:navigation-icon="{ active, onClick }">
        <q-btn v-if="active" size="md" icon="circle" color="primary" flat round dense @click="onClick" />
        <q-btn v-else size="sm" icon="o_circle" color="primary" flat round dense @click="onClick" />
      </template>
      <q-carousel-slide  class="column flex-center" v-for="theme, index in props.sections" :name=theme.orderNum>
        <div class="carousel">
          <div class="carousel-title">
            <h3>{{getTranslation(theme, 'title')}}</h3>
          </div>
          <div class="carousel-count">{{ index + 1 }}/{{ themeCount }}</div>
        </div>
      </q-carousel-slide>
    </q-carousel>
    <div class="accordion">
      <q-list bordered>
        <q-expansion-item v-model="contextOpenedRef">
          <template v-slot:header>
            <q-item-section avatar>
              <q-icon name="o_description"></q-icon>
            </q-item-section>
            <q-item-section>
              {{ $t("context.context") }} 
            </q-item-section>
          </template>
          <template v-slot>
              <q-card>
                  <q-card-section>
                    <div class="context-container__description">
                        {{props.context}}
                    </div>
                  </q-card-section>
              </q-card>
          </template>
        </q-expansion-item>
        <q-expansion-item v-bind:default-opened="props.attachmentsOpened" v-if="props.contextAttachment.length >= 1">
          <template v-slot:header>
            <q-item-section avatar>
              <q-icon name="link"></q-icon>
            </q-item-section>
            
            <q-item-section>
              <div class="attachments">
                  <div>
                      {{ $t("context.context-attachment", { count: props.contextAttachment.length }) }} 
                  </div>
                  <div  class="attachments_count">
                      [ {{props.contextAttachment.length}} ]
                  </div>
              </div>
            </q-item-section>
          </template>
          <template v-slot>
            <q-card>
              <q-card-section>
                <supporting-info-container :supportingInfos="props.contextAttachment"/>
              </q-card-section>
            </q-card>
          </template>
        </q-expansion-item>
      </q-list>
    </div>
  </div>
</template>

<style lang="scss">
.section-card {
  font-size: 16px;

  &__banner {
    display: flex;
    width: inherit;
    margin: 0 10%;

    img  {
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0px 0px;
    }
  }

  .accordion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: inherit;
    margin: 0 10%;
    background-color: $color-neutral-white;
    border-radius: 0px 0px 8px 8px;
    width: 80%;

    .q-list {
      width: inherit;
    }
    .q-expansion-item {
      border-radius: 8px;
      &:hover {
        background-color: $color-neutral-grey-100;
        font-weight: bold;
      }
    }
    .q-item.q-item-type {
      padding: $space-sm $space-md;
      height: auto;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      

      .q-icon {
        font-size: $small-title;
        padding: 0 $space-sm;
      }
    }

    .q-expansion-item__content .q-card {
      border-radius: 0px 0px 8px 8px;
    }
  }
  .attachments {
    display: flex;
    flex-direction: row;
    gap: $space-sm;

    &_count {
      font-weight: bold;
    }
  }
}

.q-carousel {
  background-color: transparent !important;

  &__slide {
    width: 80% !important;
    background-color: $color-neutral-white;
    padding: $space-md !important;
    padding-bottom: $space-xl !important;
  }

  .q-panel {
    display: flex !important;
    justify-content: center !important;

    > div{
      min-height: 120px !important;
    }
  }

  &__navigation--bottom {
    bottom: -2px !important;
  }
}

.q-btn--round {
  background-color: $color-neutral-white !important;
}

.carousel {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  flex-grow: 1;

  &-title {
    display: flex;
    justify-content: center;
    width: inherit;
    align-content: center;
    flex-wrap: wrap;

    h3 {
      text-align: center;
    }
  }

  &-count {
    color: $color-primary;
    font-size: $body-text;
    font-weight: bold;
    width: 30px;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .q-carousel {
    margin: unset;
    &__slide {
      width: 70% !important;
    }

    // &__slides-container {
    //   max-width: 200px;
    // }

    .q-panel {
      display: flex !important;
      justify-content: center !important;
      width: auto;
      background-color: $color-neutral-white;
     
      
      > div{
        min-height: 150px !important;
      }
    }

    .carousel {
      margin-bottom: $space-md;
      max-width: 343px;
    }
  }
  .section-card {
    &__banner {
      margin: unset;
    }
    .accordion {
      width: 100%;
      margin: unset;
    }
  }
}
</style>