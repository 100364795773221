<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import { getTranslation } from "@/utils/data-display-utils";

import ProjectDescription from '@/components/project/ProjectDescription.vue';
import ModuleImageText from '@/components/project/ModuleTextImage.vue';
import ModuleVideo from "@/components/project/ModuleVideo.vue";
import ModuleConsultation from "@/components/project/ModuleConsultation.vue";
import ModuleAttachments from "@/components/project/ModuleAttachments.vue";

import { Project } from "@/store/project.interface";
import { Module } from "@/store/module.interface";
import { useCommunityStore } from "@/store/communityStore";
import { modulesService } from "@/services/modules.service";
import { constant } from "@/constant";


// CONST
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const communityStore = useCommunityStore();


// LIFECYCLE
onMounted(async () => {
    await modulesService.getProjectModules(project.value.id);
})


// COMPUTED
const project = computed<Project>(() => {
    const slug = route.params.slug as string
    return communityStore.community.projects.find(project => project.slug == slug);
})

const modules = computed<Module[]>(() => {
    return project.value.modules;
})

</script>

<template>
    <div class="project-container">
        <div class="project">
            <div class="project__title">
                <h1>{{ getTranslation(project, 'title') }}</h1>
            </div>
            <div class="project__description">
                <ProjectDescription :text="getTranslation(project, 'description')" :banner="project.bannerFile" />
            </div>
            <div v-for="module in modules" class="project__module-text">
                <ModuleImageText v-if="module.module_type === constant.ProjectModule.Type.Text" :module="module"/>
                <ModuleVideo v-if="module.module_type === constant.ProjectModule.Type.Video" :module="module"/>
                <ModuleConsultation v-if="module.module_type === constant.ProjectModule.Type.Consultation" :module="module"/>
                <ModuleAttachments v-if="module.module_type === constant.ProjectModule.Type.Attachments" :module="module"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.project-container {
    display: flex;
    justify-content: center;
    padding: $space-md 0;

    .project {
        display: flex;
        flex-direction: column;
        width: 70%;
        gap: $space-lg;

        &__title {
            margin: $space-md 0 0 0;
        }
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .project-container {
        padding: $space-sm 0;

        .project {
            width: 90%;
        }
    }
}
</style>