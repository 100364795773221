import { constant } from "@/constant";
import { HomepageItem, StoredFile } from "./common.interface";
import { LanguageTranslation } from "./language.interface";
import { Module } from "./module.interface";
import { Context } from "./context.interface";

export interface DTOProject {
    id: number;
    community_id: number;
    slug: string;
    status: number;
    publication_status: number;
    visibility: number;
    banner_file: StoredFile;
    title: LanguageTranslation;
    description: LanguageTranslation;
    order_num: number;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
}

export interface Project extends HomepageItem {
    id: number;
    communityId: number;
    publicationStatus: number;
    visibility: number;
    bannerFile: StoredFile;
    orderNum: number;
    createdAt: Date;
    updatedAt: Date;
    deletedAt?: Date;
    modules?: Module[];
}

export function parseProject(dto: DTOProject): Project {
    return {
        itemType: constant.HomePageItem.Type.Project,
        id: dto.id,
        communityId: dto.community_id,
        slug: dto.slug,
        status: dto.status,
        publicationStatus: dto.publication_status,
        visibility: dto.visibility,
        bannerFile: dto.banner_file,
        title: dto.title,
        description: dto.description,
        orderNum: dto.order_num,
        createdAt: new Date(dto.created_at),
        updatedAt: new Date(dto.updated_at),
        deletedAt: dto.deleted_at ? new Date(dto.deleted_at) : undefined,
    } as Project;
}