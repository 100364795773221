<script setup lang="ts">
import { Cropper, CircleStencil, RectangleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import BaseButton from "@/components/base/BaseButton.vue";
import { computed, onMounted, reactive, ref, shallowRef } from "vue";

type ImageType = "avatar" | "community" | "card";
type StencilProps = {
  aspectRatio?: number
  minAspectRatio?: number
  maxAspectRatio?: number
  handlers?: object
  lines?: object
  movable?: boolean,
  resizable?: boolean
}

// PROPS
const props = defineProps<{
  image: string
  type?: ImageType
  disabled?: boolean
}>();

// EMITS
const emit = defineEmits<{
  (e: "upload", image: string): void
  (e: "save", image: string): void
  (e: "remove"): void
  (e: "cancel"): void,
}>();

// REACTIVE
const image = reactive({
  src: "",
  type: "image/jpg",
});

// REFS
const cropRef = ref();
const bannerRatio = ref(6.68);
const setImage = ref(false);
const editMode = ref<boolean>(false);
const isSaving = ref<boolean>(false);
const file = ref();
const selectedStencil = shallowRef(CircleStencil)
const stencilProps = ref<StencilProps>({
  handlers: {},
  lines: {},
  movable: false,
  resizable: false
})

// LIFECYCLE
onMounted(() => {
  image.src = props.image
  selectStencil()
})

// COMPUTED
const hasImageSet = computed(() => {
  if(props.image) {
    return props.image
  } else {
    return false
  }
})

const isInEditMode = computed(() => {
  return editMode.value && hasImageSet
})

const aspectRatioNumber = computed(() => {
  return bannerRatio.value === 6.68 ? 3.51 : 6.68
})

const isCommunityImage = computed(() => {
  return props.type === 'community'
})

defineExpose({
  saveImage
});

// FUNCTIONS
function saveImage() {
    if (cropRef.value) {
      const { canvas } = cropRef.value.getResult();
      return canvas.toDataURL()
    }
}

function openImage(event: { target: { files: any; }; }) {
  const {files} = event.target;
  if (files && files[0]) {
    if (image.src) {
        URL.revokeObjectURL(image.src);
    }
    image.src = URL.createObjectURL(files[0]);
    image.type = files[0].type;
    setImage.value = true
    toggleEditMode()
  }
}

function toggleEditMode() {
  editMode.value = !editMode.value
  if (editMode.value) {
    stencilProps.value.handlers = {
      eastNorth: true,
      north: true,
      westNorth: true,
      west: true,
      westSouth: true,
      south: true,
      eastSouth: true,
      east: true
    }
    stencilProps.value.lines = {
      north: true,
      west: true,
      south: true,
      east: true,
    }
    stencilProps.value.movable = true
    stencilProps.value.resizable = true
  } else {
    stencilProps.value.handlers = {}
    stencilProps.value.lines = {}
    stencilProps.value.movable = false
    stencilProps.value.resizable = false
  }
}

function removeImage() {
  emit('remove')
}

function selectStencil() {
  switch (props.type) {
    case "card":
      selectedStencil.value = RectangleStencil;
      stencilProps.value.aspectRatio = 1;
      break;
    case "community":
      selectedStencil.value = RectangleStencil;
      stencilProps.value.aspectRatio = aspectRatioNumber.value;
      break;
    default:
      selectedStencil.value = CircleStencil;
      stencilProps.value.aspectRatio = 1;
      break;
  }
}

function openImageDialog() {
  file.value.click();
}

function uploadImage(event: { target: { files: any; }; }) {
  const { files } = event.target;
  if (files && files[0]) {
    if (image.src) {
      URL.revokeObjectURL(image.src);
    }
    
    image.src = URL.createObjectURL(files[0]);
    image.type = files[0].type;
    emit("update", URL.createObjectURL(files[0]))
    setImage.value = true
    if(!editMode.value){
      toggleEditMode()
    }
  }
}
</script>

<template>
  <div class="base-image-upload" v-if="hasImageSet">
    <cropper :key="bannerRatio" ref="cropRef" class="cropper" :src="props.image" :image-restriction="'fit-area'"
              :stencil-component=selectedStencil :stencil-props=stencilProps></cropper>
    <div class="base-image-upload__buttons">
      <q-btn v-if="image.src" icon="o_delete" @click="removeImage" flat/>
      <q-btn v-else icon="o_add_a_photo" @click="openImageDialog" flat :loading="isSaving"/>
      <input class="base-image-upload__new--file-upload" type="file" ref="file" accept="image/*"
            @change="uploadImage"/>
    </div>
  </div>

  <div class="base-image-upload__new" v-else>
    <div class="base-image-upload__new--border" @click="openImageDialog()">
      <div class="base-image-upload__new--text">{{ $t('upload-image-modal.add-photo') }}</div>
    </div>
    <input class="base-image-upload__new--file-upload" type="file" ref="file" accept="image/*"
            @change="uploadImage"/>
  </div>
</template>

<style lang="scss">

.base-image-upload {
  width: 750px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;

  .cropper {
    height: 400px;
    width: 90%;
    border: 2px $color-neutral-grey-200 dashed;
    background-color: $color-neutral-white;
  }

  &__new {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    &--border {
      background-color: $color-neutral-white;
      border: 2px $color-neutral-grey-200 dashed;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      transition: 200ms;
      cursor: pointer;

      &:hover {
        border-color: $color-primary;
      }
    }

    &--text {
      font-size: $small-title;
      font-weight: bold;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-neutral-grey-400;
      margin-bottom: 20px;
      transition: 200ms;

      &:hover {
        color: $color-primary;
      }
    }

    &--button {
      margin-top: $space-md;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    input {
      display: none;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: $space-md;

    input {
      display: none;
    }

    &--edit {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }


    &--right {
      display: flex;
    }

    &--ratio {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-right: $space-lg;
    }

    &--modify {
      width: 100%;
      display: flex;
    }

    &--left {
      .base-button {
        margin-right: $space-lg
      }
    }
  }
}

.cropper {
  height: 400px;
  width: 800px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.button {
  color: white;
  font-size: $sub-header;
  padding: 10px 20px;
  background: $color-primary;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.5s;
  border: none;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &:hover {
    background: #2F2F2F;
  }

  input {
    display: none;
  }
}
</style>