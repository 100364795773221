<script setup lang="ts">
import { computed, onMounted, ref, provide } from "vue";
import BaseButton from "../base/BaseButton.vue";


import { getTranslation } from "@/utils/data-display-utils";
import { Module, ProjectModuleConsultationConfigDto} from '@/store/module.interface';
import { useCommunityStore } from "@/store/communityStore";
import { Consultation } from "@/store/consultation.interface";
import { currentLocale } from "@/plugins/i18n";
import { intlFormat } from "date-fns";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/userStore";

const isAllowed = ref<boolean>(false);
const isCompleted = ref<boolean>(false);

// CONST
const { t } = useI18n();
const communityStore = useCommunityStore();
const userStore = useUserStore();
const router = useRouter();

// PROPS
const props = defineProps<{
    module: Module;
}>();


const moduleConfig = props.module.config as ProjectModuleConsultationConfigDto;

// LIFECYCLE
onMounted(async () => {
    if (consultation.value) {
        isAllowed.value = 
            communityStore.isUserAllowedAccessConsultation(consultation.value, userStore.user).allowed &&
            communityStore.isUserAllowedIfPrelaunchConsultation(consultation.value);
        isCompleted.value = communityStore.isConsultationCompleted(consultation.value);
    }
})

// COMPUTED
const consultation = computed<Consultation>(() => {
    return communityStore.community.consultations.find(consultation => consultation.id === moduleConfig.consultationId);
})

// FUNCTIONS
function formatDate(date: Date) {
    return intlFormat(date, { dateStyle: 'long'}, { locale: currentLocale() });
}

function openConsultation(){
    router.push({
        name: "consultations",
        params: { slug: consultation.value.slug }
    });
}

function openConsultationResults() {
    provide("results", true);
    router.push({
        name: "consultations",
        params: { slug: consultation.value.slug }
    });
}

</script>

<template>
    <div v-if="isAllowed" class="consultation-module-wrapper">
        <div class="consultation-module highlight">
            <div v-if="consultation.bannerFile" class="consultation-module__banner">
                <img :src="consultation.bannerFile.url"/>
            </div>
            <div class="consultation-module__content">
                <div class="consultation-module__content-left">
                    <div class="consultation-module__content-title">
                        <h3>{{ getTranslation(props.module, 'title') }}</h3>
                    </div>
                    <div v-if="consultation.beginDate && consultation.endDate"  class="consultation-module__content-date">
                        {{ formatDate(consultation.beginDate) }} - {{ formatDate(consultation.endDate) }}
                    </div>
                    <div v-else-if="consultation.endDate"  class="consultation-module__content-date">
                        {{ t('consultation.end-date') }}: {{ formatDate(consultation.endDate) }}
                    </div>
                    <div class="consultation-module__content-bottom">
                        <q-chip :ripple=false square size="md" :label="t('consultation.public')"/>
                    </div>
                </div>
                <div class="consultation-module__content-right">
                    <q-btn v-if="!isCompleted" fab color="secondary" @click="openConsultation">
                        <span class="q-mx-sm">{{ t('buttons.participate') }}</span>
                    </q-btn>
                    <q-btn v-else fab color="secondary" @click="openConsultationResults">
                        <span class="q-mx-sm">{{ t('buttons.see-results') }}</span>
                    </q-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.consultation-module-wrapper {
    width: 100%;
    margin-bottom: $space-md;

    .highlight {
        background-color: #cedbe4;
        border-radius: 8px;
        padding: $space-lg;
    }

    .consultation-module {
        display: flex;
        flex-direction: column;

        &__banner {

            img {
                width: 100%;
                border-radius: 8px;
            }
        }

        h3 {
            margin-bottom: $space-sm !important;
        }

        &__content {
            padding: $space-sm 0 0 0;
            display: flex;
            flex-direction: row;

            &-left {
                width: 70%
            } 

            &-right {
                width: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-date {
                color: $color-neutral-grey-400;
            }

            &-bottom {
                display: flex;
                justify-content: space-between;
                padding: $space-sm 0 0 0;

                .q-chip {
                    background-color: #ADC2D2;
                    margin: 0;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .consultation-module-wrapper {
        .consultation-module {
            &__content-bottom {

                .q-chip {
                    font-size: $body-text !important;
                    margin-bottom: $space-md;
                    width: fit-content;
                }
                flex-direction: column;
            }
        }
    }
}
</style>